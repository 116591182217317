<template>
  <b-card title="Users">
    <!-- chart -->
    <chartjs-component-doughnut-chart
      :height="275"
      :data="doughnutChart.data"
      :options="doughnutChart.options"
      class="mb-3"
    />
    <div
      v-for="(stock, index) in stockData"
      :key="stock.device"
      :class="index < stockData.length - 1 ? 'mb-1' : ''"
      class="d-flex justify-content-between"
    >
      <div class="d-flex align-items-center">
        <feather-icon :icon="stock.symbol" size="16" :class="stock.color" />
        <span class="font-weight-bold ml-75 mr-25">{{ stock.device }}</span>
        <!-- <span>- {{ stock.percentage }} user</span> -->
      </div>
    </div>
    <!--/ stocks -->
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import { $themeColors } from "@themeConfig";
import ChartjsComponentDoughnutChart from "./charts-components/ChartjsComponentDoughnutChart.vue";
// import chartjsData from "./chartjsData";

export default {
  components: {
    ChartjsComponentDoughnutChart,
    BCard,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
  },

  created() {
    this.users = this.users.sort((a, b) => a.status_id - b.status_id)
    this.doughnutChart = {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        cutoutPercentage: 60,
        legend: { display: false },
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              const label = data.datasets[0].labels[tooltipItem.index] || "";
              const value = data.datasets[0].data[tooltipItem.index];
              const output = ` ${label} : ${value} user`;
              return output;
            },
          },
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: "rgba(0, 0, 0, 0.25)",
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
      },
      data: {
        datasets: [
          {
            labels: this.users.map((user) => user.status_name),
            data: this.users.map((user) => user.count),
            backgroundColor: ["#28dac6", "#FDAC34", "#DC3545"],
            borderWidth: 0,
            pointStyle: "rectRounded",
          },
        ],
      },
    };
  },
  data() {
    return {
      doughnutChart: {},
      stockData: [
        {
          device: "ACTIVE",
          symbol: "MonitorIcon",
          color: "text-primary",
        },
        {
          device: "LOCK",
          symbol: "TabletIcon",
          color: "text-danger",
        },
        {
          device: "INACTIVE",
          symbol: "TabletIcon",
          color: "text-warning",
        },
      ],
    };
  },
};
</script>
