<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>User login daily</b-card-title>
    </b-card-header>
    <div class="text-left" style="padding-left:1.5rem">Unit:(User)</div>
    <b-card-body>
      <chartjs-component-bar-chart
          :height="365"
          :data="latestBarChart.data"
          :options="latestBarChart.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCardTitle } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { $themeColors } from "@themeConfig";
import ChartjsComponentBarChart from "./charts-components/ChartjsComponentBarChart.vue";
// import chartjsData from "./chartjsData";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    flatPickr,
    ChartjsComponentBarChart,
  },
  props: {
    chartDataUserLogin: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.latestBarChart = {
      data: {
        labels: this.chartDataUserLogin?.labels,
        datasets: [
          {
            label: "Number User",
            data: this.chartDataUserLogin?.dataset,
            backgroundColor: "#772cf2",
            borderColor: "transparent",
          },
        ],
      },
      options: {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: "bottom",
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        tooltips: {
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: "rgba(0, 0, 0, 0.25)",
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                color: "rgba(200, 200, 200, 0.2)",
                zeroLineColor: "rgba(200, 200, 200, 0.2)",
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontColor: "#6e6b7b",
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: "rgba(200, 200, 200, 0.2)",
                zeroLineColor: "rgba(200, 200, 200, 0.2)",
              },
              ticks: {
                min: 0,
                fontColor: "#6e6b7b",
              },
            },
          ],
        },
      },
    };
  },
  data() {
    return {
      // chartjsData,
      // rangePicker: ["2019-05-01", "2019-05-10"],
      latestBarChart: {},
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
