import CardTransactionService from '@/libs/card-transaction.service'

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchCardTransactions(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				CardTransactionService.getCardTransactionLists(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchCardType(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				CardTransactionService.getCardType(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchDataChart(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				CardTransactionService.getDataChart(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
	}
}
